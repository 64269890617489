.react-html5-camera-photo {
  background-color: #000;
  display: flex;
  height: 100%;
  align-items: center;
}

#container-circles{
  bottom: 100px;
}

.demo-image-preview {
  position: relative;
  text-align: center;
  height: 100%;
  background-color: #000;
  display: flex;
  height: 100%;
  align-items: center;
}


.demo-image-preview  > .image-container,
.demo-image-preview  > .image-container > img {
  width: 100%;
}

.actions {
  position: absolute;
  bottom: 0;
  padding: 20px;
  width: 100%;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  grid-gap: 20px;
}