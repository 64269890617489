html {
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#map { position: absolute; top: 0; bottom: 0; width: 100%; margin-bottom: -200px; }
#root {
  height: 100%;
}
.map {
  height: 92.5%;
  padding-bottom: -100px;
  margin-bottom: -200px; 
}

.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}