/**
 * ==============================================
 * Dot Bricks
 * ==============================================
 */

 .dot-bricks {
    position: relative;
    top: 5px; /* $spacing * 0.5 = 15px * 0.5 = 8px */
    left: -9992px;
  
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #8c8526;
    color: #8c8526;
  
    box-shadow:
      calc(9999px - 5px) -10px 0 0 #8c8526, /* $x1 $y2 */
      calc(9999px - 5px) 0 0 0 #8c8526, /* $x1 $y1 */
      calc(9999px - 5px + 10px) 0 0 0 #8c8526; /* $x2 $y1 */
    animation: dot-bricks 2s infinite ease;
  }
  
  @keyframes dot-bricks {
    0% {
      box-shadow:
        calc(9999px - 5px) -10px 0 0 #8c8526, /* $x1 $y2 */
        calc(9999px - 5px) 0 0 0 #8c8526, /* $x1 $y1 */
        calc(9999px - 5px + 10px) 0 0 0 #8c8526; /* $x2 $y1 */
    }
  
    8.333% {
      box-shadow:
        calc(9999px - 5px + 10px) -10px 0 0 #8c8526, /* $x2 $y2 */
        calc(9999px - 5px) 0 0 0 #8c8526, /* $x1 $y1 */
        calc(9999px - 5px + 10px) 0 0 0 #8c8526; /* $x2 $y1 */
    }
  
    16.667% {
      box-shadow:
        calc(9999px - 5px + 10px) -10px 0 0 #8c8526, /* $x2 $y2 */
        calc(9999px - 5px) -10px 0 0 #8c8526, /* $x1 $y2 */
        calc(9999px - 5px + 10px) 0 0 0 #8c8526; /* $x2 $y1 */
    }
  
    25% {
      box-shadow:
        calc(9999px - 5px + 10px) -10px 0 0 #8c8526, /* $x2 $y2 */
        calc(9999px - 5px) -10px 0 0 #8c8526, /* $x1 $y2 */
        calc(9999px - 5px) 0 0 0 #8c8526; /* $x1 $y1 */
    }
  
    33.333% {
      box-shadow:
        calc(9999px - 5px + 10px) 0 0 0 #8c8526, /* $x2 $y1 */
        calc(9999px - 5px) -10px 0 0 #8c8526, /* $x1 $y2 */
        calc(9999px - 5px) 0 0 0 #8c8526; /* $x1 $y1 */
    }
  
    41.667% {
      box-shadow:
        calc(9999px - 5px + 10px) 0 0 0 #8c8526, /* $x2 $y1 */
        calc(9999px - 5px + 10px) -10px 0 0 #8c8526, /* $x2 $y2 */
        calc(9999px - 5px) 0 0 0 #8c8526; /* $x1 $y1 */
    }
  
    50% {
      box-shadow:
        calc(9999px - 5px + 10px) 0 0 0 #8c8526, /* $x2 $y1 */
        calc(9999px - 5px + 10px) -10px 0 0 #8c8526, /* $x2 $y2 */
        calc(9999px - 5px) -10px 0 0 #8c8526; /* $x1 $y2 */
    }
  
    58.333% {
      box-shadow:
        calc(9999px - 5px) 0 0 0 #8c8526, /* $x1 $y1 */
        calc(9999px - 5px + 10px) -10px 0 0 #8c8526, /* $x2 $y2 */
        calc(9999px - 5px) -10px 0 0 #8c8526; /* $x1 $y2 */
    }
  
    66.666% {
      box-shadow:
        calc(9999px - 5px) 0 0 0 #8c8526, /* $x1 $y1 */
        calc(9999px - 5px + 10px) 0 0 0 #8c8526, /* $x2 $y1 */
        calc(9999px - 5px) -10px 0 0 #8c8526; /* $x1 $y2 */
    }
  
    75% {
      box-shadow:
        calc(9999px - 5px) 0 0 0 #8c8526, /* $x1 $y1 */
        calc(9999px - 5px + 10px) 0 0 0 #8c8526, /* $x2 $y1 */
        calc(9999px - 5px + 10px) -10px 0 0 #8c8526; /* $x2 $y2 */
    }
  
    83.333% {
      box-shadow:
        calc(9999px - 5px) -10px 0 0 #8c8526, /* $x1 $y2 */
        calc(9999px - 5px + 10px) 0 0 0 #8c8526, /* $x2 $y1 */
        calc(9999px - 5px + 10px) -10px 0 0 #8c8526; /* $x2 $y2 */
    }
  
    91.667% {
      box-shadow:
        calc(9999px - 5px) -10px 0 0 #8c8526, /* $x1 $y2 */
        calc(9999px - 5px) 0 0 0 #8c8526, /* $x1 $y1 */
        calc(9999px - 5px + 10px) -10px 0 0 #8c8526; /* $x2 $y2 */
    }
  
    100% {
      box-shadow:
        calc(9999px - 5px) -10px 0 0 #8c8526, /* $x1 $y2 */
        calc(9999px - 5px) 0 0 0 #8c8526, /* $x1 $y1 */
        calc(9999px - 5px + 10px) 0 0 0 #8c8526; /* $x2 $y1 */
    }
  }
  