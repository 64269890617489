.photo-view-container {
  position: relative;
  text-align: center;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-container {
  width: 100%;

}
.photo-view-container > .photo {
  width: 100%;
}

.photo-view-container > .photo-description {
  color:#fff
}